'use client';
import React from 'react';
import { Trans as TransBase } from 'react-i18next';
import i18n from '../../locales/i18n';

interface ITransProps {
	i18nKey?: string;
}

const Trans: React.FC<ITransProps> = (props) => {
	return (
		<TransBase i18n={i18n} i18nKey={props.i18nKey}>
			{props.i18nKey}
		</TransBase>
	);
};
export default React.memo(Trans);
