import Image from 'next/image';
import { FC } from 'react';

const SiteLogo: FC = () => {
	return (
		<div className="site-logo" title="site logo, travel price drops">
			<Image
				src="/images/logos/logo.png"
				alt="TravelPriceDrops Logo"
				width={255}
				height={35}
				quality={100}
				style={{ width: 'auto' }}
			/>
		</div>
	);
};

export default SiteLogo;
