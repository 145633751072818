'use client';
import { HIGHLIGHT_CLICKED } from 'app/modules/PsEvents';
import PubSub from 'pubsub-js';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import Helper from '../../modules/Helper';
import FadeTransition from './FadeTransition';

type BodyHighlightProps = {
	openedInput?: string;
	lng: string;
	openInput: (name?: string | null) => void;
};

const BodyHighlight: FC<BodyHighlightProps> = ({ openedInput, openInput }) => {
	const onClick = () => {
		PubSub.publish(HIGHLIGHT_CLICKED, openedInput);
		setTimeout(() => {
			openInput(null);
		}, 10);
	};

	// TODO postfix should not be hardcoded
	return (
		<FadeTransition timeout={1000} in={!Helper.isEmpty(openedInput) && openedInput !== 'mobile-select-box' && openedInput !== 'mobile-select-box-second'}>
			<div onClick={onClick} id="body-highlight" />
		</FadeTransition>
	);
};

const mapStateToProps = (state: any) => ({
	openedInput: state.base.openedInput,
	lng: state.base.lng,
});

export default React.memo(connect(mapStateToProps, { openInput })(BodyHighlight));
