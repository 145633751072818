'use client';
import dynamic from 'next/dynamic';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	openChildInput,
	openInput,
	togglePreventEnter,
	togglePreventFocus,
	toggleProgressLoading,
} from '../../../actions/baseActions';
import { doSearchCars } from '../../../actions/carActions';
import { doSearchFlights } from '../../../actions/flightActions';
import Helper from '../../modules/Helper';
import BodyHighlight from '../Common/BodyHighlight';
import CookieNotice from '../Common/CookieNotice';
import Footer from './MainLayout/Footer';
import Header from './MainLayout/Header';

const ProgressLoading = dynamic(() => import('../Common/ProgressLoading'), { ssr: false });

type InputName = {
	inputs: string[];
	childInputs: Record<string, string>;
};
const inputNames: Record<string, InputName> = {
	flights: {
		inputs: ['airport-from', 'airport-to', 'date-from', 'date-to'],
		childInputs: {},
	},
	cars: {
		inputs: ['airport-from', 'airport-to', 'date-from', 'date-to'],
		childInputs: {
			'date-from': 'from-date-picker',
			'date-to': 'to-date-picker',
		},
	},
	hotels: {
		inputs: ['hotel-destination', 'check-in-date', 'check-out-date', 'room-type'],
		childInputs: {},
	},
	cruises: {
		inputs: ['cruise-destination', 'departure-months', 'cruise-duration', 'room-type'],
		childInputs: {},
	},
};

interface MainLayoutProps {
	children: React.ReactNode;
	header?: React.ReactNode;
}

const MainLayout: FC<MainLayoutProps & MainLayoutReduxProps> = (props) => {
	const onTabPressed = (e: KeyboardEvent) => {
		if (props.preventEnter) {
			props.togglePreventEnter(false);
			return true;
		}

		const INPUTS = inputNames[props.enabledVert].inputs;
		const CHILD_INPUTS = inputNames[props.enabledVert].childInputs;

		let openedInput;
		const openedInputId = document.activeElement?.id;

		if (document.hasFocus()) {
			openedInput = openedInputId?.replace('-input', '');
		}

		if (!openedInput) {
			openedInput = props.openedInput;
		}
		switch (e.key) {
			// Tab
			case 'Tab':
				if (INPUTS.indexOf(openedInput) !== -1) {
					if (CHILD_INPUTS[openedInput]) {
						props.openChildInput(CHILD_INPUTS[openedInput]);
					}
					props.openInput(openedInput);
				}
				if (INPUTS.indexOf(openedInput) === -1 && openedInputId !== '') {
					props.openInput(null);
					props.openChildInput(null);
				}
				break;
			// Esc
			case 'Escape':
				props.openInput(null);
				props.openChildInput(null);
				break;
			// Enter
			case 'Enter':
				if (
					(INPUTS.indexOf(openedInput) !== -1 || openedInputId === '') &&
					['date-from', 'date-to'].indexOf(openedInput) === -1
				) {
					switch (props.enabledVert) {
						case 'flights':
							doSearchFlights();
							break;
						case 'cars':
							doSearchCars();
							break;
						default:
							break;
					}
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);

		if (parsed.hasOwnProperty('autoredirect') && parsed.autoredirect === '1') {
			props.toggleProgressLoading(true);
		}
		window.addEventListener('keyup', onTabPressed);

		return () => {
			window.removeEventListener('keyup', onTabPressed);
		};
	}, []);

	return (
		<div>
			<ProgressLoading partner={props.partner} />
			<div
				style={{
					display: props.enableProgressLoading ? 'none' : 'block',
				}}
			>
				<BodyHighlight />
				<CookieNotice />

				{props.header ?? (<Header />)}
				{props.children}
				<Footer />
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	const { enabledVert, openedInput, preventEnter, enableProgressLoading } = state.base;
	const { partner } = state.passthru;

	return {
		enabledVert,
		openedInput,
		preventEnter,
		enableProgressLoading,
		partner,
	};
};

const mapDispatchToProps = {
	openInput,
	openChildInput,
	doSearchCars,
	toggleProgressLoading,
	doSearchFlights,
	togglePreventEnter,
	togglePreventFocus,
};
type MainLayoutReduxProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
