'use client';
import Image from 'next/image';
import { FC } from 'react';
import { useStore } from 'store';
import Helper from '../../../modules/Helper';
import Trans from '../../Common/Trans';

const PhoneNumber: FC = () => {
	const store = useStore();
	const vert = store.getState().base.enabledVert;
	const phoneNumber = store.getState().base.phoneNumber;
	const showPhoneNumber = store.getState().base.showPhoneNumber;
	if (!showPhoneNumber) return <></>;

	return (
		<div className="ml-auto">
			<a href={`tel:${phoneNumber}`} id="phone-number-container" className=" d-none d-sm-block">
				<div>
					<span id="phone-number-text">{phoneNumber}</span>
				</div>
				<div id="powered-by" className="d-flex align-items-center">
					{vert === 'flights' ? (
						<>
							<span>{Helper.trans('keywords.poweredBy')} </span>
							<Image
								className="ms-1"
								alt="Smart Fares"
								width={60}
								height={11}
								src="/images/smart-fares-logo.png"
								style={{ objectFit: 'contain' }}
							/>
						</>
					) : (
						''
					)}
					{vert === 'hotels' ? (
						<>
							<span>{Helper.trans('keywords.poweredBy')} </span>
							<img alt="HotelPlanner" src="/images/hotel-planner.webp" className="smart-fares-logo ml-1" />
						</>
					) : (
						''
					)}
				</div>
			</a>
			<div id="phone-number-container-mobile" className=" d-block d-sm-none">
				<a href={`tel:${phoneNumber}`} id="btn-call">
					<i className="icon-phone" />
					<span>
						<Trans i18nKey="keywords.callNow" />
					</span>
				</a>
			</div>
		</div>
	);
};

export default PhoneNumber;
