'use client';
import SiteLogo from 'app/components/Common/SiteLogo';
import PhoneNumber from 'app/components/Layouts/MainLayout/PhoneNumber';
import classNames from 'classnames';
import { useSearchParams } from 'next/dist/client/components/navigation';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { Navbar } from 'react-bootstrap';
import Helper from '../../../modules/Helper';

const NavBarLinks = dynamic(() => import('./NavBarLinks'), { ssr: false });

const Header: FC = () => {
	const searchParams = useSearchParams();
	const lng = searchParams?.get('lng') as string;
	const isRtl = Helper.isRtlLanguage(lng);
	const homeUrl = lng ? `/?lng=${lng}` : '/';

	return (
		<div>
			<Navbar expand="lg">
				<div
					className={classNames('container', {
						'flex-row-reverse': isRtl,
					})}
				>
					<Navbar.Brand href={homeUrl}>
						<SiteLogo />
					</Navbar.Brand>
					<Navbar.Collapse id="main-navbar-nav">
						<NavBarLinks />
					</Navbar.Collapse>
					<Navbar id="auth-navbar-nav" className="pr-0">
						<PhoneNumber />
					</Navbar>
				</div>
			</Navbar>
		</div>
	);
};
export default Header;
