'use client';
import classNames from 'classnames';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Helper from '../../../modules/Helper';

const Footer: FC = () => {
	const params = useSearchParams();
	const lng = params?.get('lng') as string;
	const isRtl = Helper.isRtlLanguage(lng);
	const { t } = useTranslation();

	return (
		<footer>
			<div className="container ">
				<div className="row " style={{ minHeight: '220px' }}>
					<div className="col-12 text-center pt-5 pb-5">
						<ul id="footer-links">
							<li>
								<Link target="_blank" href={Helper.getLink('/contact-us')}>
									{t('keywords.contactUs')}
								</Link>
							</li>
							<li>
								<Link target="_blank" href={Helper.getLink('/terms')}>
									{t('keywords.termsOfUser')}
								</Link>
							</li>
							<li>
								<Link target="_blank" href={Helper.getLink('/privacy')}>
									{t('keywords.privacyPolicy')}
								</Link>
							</li>
						</ul>
					</div>
					<br />
					<div
						className={classNames('col-12 pt-1 pb-1 about-us-text', {
							'text-end': isRtl,
						})}
					>
						<p>{t('main.footerAboutUs')}</p>
					</div>
					<div className="col-12 text-center  pt-4 pb-3">
						<p>{t('main.footerCopyrightText', { currentYear: new Date().getFullYear() })}</p>
					</div>
				</div>
			</div>
		</footer>
	);
};
export default React.memo(Footer);
